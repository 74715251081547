import React, { FC } from "react";
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

interface Props {
  link?: string;
  doc?: string;
}

const IFramePDF: FC<Props> = (props) => {
  return (
    <div style={{backgroundColor: 'white', height: '100vh', overflow: "hidden"}}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3/build/pdf.worker.min.js">
            <Viewer fileUrl={props.link || window.location.search.replace('?doc=', '')} />
        </Worker>
    </div>
  );
};

export default IFramePDF;
