import React, { FC, useContext, useState } from "react";
import { LangContext } from "../../context/lang";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "../../scss/_homeServices.scss";

import btn from "../../img/chevron-right.png";

import Img1en from "../../img/en/home/services/1.Car insurance.png";
import Img2en from "../../img/en/home/services/2.Car services.png";
import Img3en from "../../img/en/home/services/3.Car value.png";
import Img4en from "../../img/en/home/services/4.Auction.png";
import Img5en from "../../img/en/home/services/5.Roadside assistance.png";
import Img6en from "../../img/en/home/services/6.Car parking.png";
import Img7en from "../../img/en/home/services/7.Fuel services.png";
import Img8en from "../../img/en/home/services/8.Accident reporting.png";
import Img9en from "../../img/en/home/services/9.Traffic fines.png";
import Img1uk from "../../img/uk/home/services/1.png";
import Img2uk from "../../img/uk/home/services/2.png";
import Img3uk from "../../img/uk/home/services/3.png";
import Img4uk from "../../img/uk/home/services/4.png";
import Img5uk from "../../img/uk/home/services/5.png";
import Img6uk from "../../img/uk/home/services/6.png";
import Img7uk from "../../img/uk/home/services/7.png";
import Img8uk from "../../img/uk/home/services/8.png";
import Img9uk from "../../img/uk/home/services/9.png";
import Img1tr from "../../img/tr/home/services/1.Sigorta.png";
import Img2tr from "../../img/tr/home/services/2.Oto servisleri.png";
import Img3tr from "../../img/tr/home/services/3.Arac.png";
import Img4tr from "../../img/tr/home/services/4.Ac.png";
import Img5tr from "../../img/tr/home/services/5.Yol yard--m.png";
import Img6tr from "../../img/tr/home/services/6.Otopark.png";
import Img7tr from "../../img/tr/home/services/7.Yak-t.png";
import Img8tr from "../../img/tr/home/services/8.Kaza tespit tutanag.png";
import Img9tr from "../../img/tr/home/services/9.Trafik cezalar.png";

import Img1 from "../../img/home/services/1.insurance.png";
import Img2 from "../../img/home/services/2.services.png";
import Img3 from "../../img/home/services/3.value.png";
import Img4 from "../../img/home/services/4.auction.png";
import Img5 from "../../img/home/services/5.assistance.png";
import Img6 from "../../img/home/services/6.parking.png";
import Img7 from "../../img/home/services/7.fuel.png";
import Img8 from "../../img/home/services/8.accident.png";
import Img9 from "../../img/home/services/9.fine.png";

// Images for Dots
const arrDots = [
  {
    img: {
      src: Img1,
      alt: "home_slider_header_insurance",
    },
  },
  {
    img: {
      src: Img2,
      alt: "home_slider_header_services",
    },
  },
  {
    img: {
      src: Img3,
      alt: "home_slider_header_value",
    },
  },
  {
    img: {
      src: Img4,
      alt: "home_slider_header_auction",
    },
  },
  {
    img: {
      src: Img5,
      alt: "home_slider_header_assistance",
    },
  },
  {
    img: {
      src: Img6,
      alt: "home_slider_header_parking",
    },
  },
  {
    img: {
      src: Img7,
      alt: "home_slider_header_fuel",
    },
  },
  {
    img: {
      src: Img8,
      alt: "home_slider_header_accident",
    },
  },
  {
    img: {
      src: Img9,
      alt: "home_slider_header_fines",
    },
  },
];

// Images for Grid. src
const images = {
  EN: {
    img1: Img1en,
    img2: Img2en,
    img3: Img3en,
    img4: Img4en,
    img5: Img5en,
    img6: Img6en,
    img7: Img7en,
    img8: Img8en,
    img9: Img9en,
  },
  UK: {
    img1: Img1uk,
    img2: Img2uk,
    img3: Img3uk,
    img4: Img4uk,
    img5: Img5uk,
    img6: Img6uk,
    img7: Img7uk,
    img8: Img8uk,
    img9: Img9uk,
  },
  TR: {
    img1: Img1tr,
    img2: Img2tr,
    img3: Img3tr,
    img4: Img4tr,
    img5: Img5tr,
    img6: Img6tr,
    img7: Img7tr,
    img8: Img8tr,
    img9: Img9tr,
  },
};

type Lang = "EN" | "UK" | "TR";
const HomeServices: FC = () => {
  const {
    state: { language },
    dispatch: { translate },
  } = useContext(LangContext);


  const [dotsSortIndex, setDotsSortIndex] = useState<number[]>(
    Array.from({ length: arrDots.length }, (_, i) => i)
  );

  const [fadeSlide, setFadeSlide] = useState<number | null>(null);

  const handleAnimationEnd = (index: number) => {
    if (index === fadeSlide) {
      setFadeSlide(null);
    }
  }

  let settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    pauseOnFocus: true,
    pauseOnHover: true,
    draggable: true,
    lazyload: true,
    focusOnSelect: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    touchMove: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    cssEase: 'linear',
    waitForAnimate: false,
    adaptiveHeight: true,
    beforeChange: (currentSlide: number, nextSlide: number) => {
      if (nextSlide === currentSlide) return

      setFadeSlide(nextSlide);

      const newOrder = [...dotsSortIndex];

      const indexOfNextSlide = newOrder.indexOf(nextSlide);
      const indexOfCurrentSlide = newOrder.indexOf(currentSlide);

      newOrder.splice(indexOfNextSlide, 1);
      newOrder.splice(indexOfCurrentSlide, 1);

      newOrder.unshift(nextSlide);
      newOrder.push(currentSlide);

      setDotsSortIndex(newOrder);
    },
    appendDots: function (dots: any) {
      return (
        <div
        // className='animated-container'
        // style={
        //   {
        //     overflow: 'hidden',
        //     //     // width: '61%',
        //     //     // transform: fadeSlide !== null ? 'translateX(100%)' : 'translateX(0)',
        //     //     // // animation: fadeSlide !== null ? 'fadeOut 0.5s ease-in-out' : '',
        //     transform: fadeSlide !== null ? 'translateX(-16%)' : 'translateX(0)',
        //   }
        // }
        >
          <ul>
            {dotsSortIndex.map((orderIndex) => dots[orderIndex])}
          </ul>
        </div>
      )
    },
    customPaging: (index: any) => {
      return (
        <div className={`custom_dots ${index === fadeSlide ? 'fade-out' : ''}`}
          onAnimationEnd={() => handleAnimationEnd(index)}
        >
          <div className={`home_slider_li-img`}>
            <img src={arrDots[index].img.src} alt={arrDots[index].img.alt} draggable="false" />
          </div>
          <p className="dots_text">
            {/* {index} */}
            {translate(arrDots[index].img.alt)}</p>
        </div>
      );
    },
  };

  return (
    <section className="home_services">
      <div className="cont cont_sm">
        <h6>{translate("home_services_name")}</h6>
        <h2 id="feature">{translate("home_services_bigtitle")}</h2>
      </div>
      <div className="home_slider-styles">
        <Slider
          // ref={sliderRef}
          // afterChange={setCurrentSlide}
          {...settings}
        >
          <div key={0} className="grid insurance">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img1}
                  alt="insurance"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_insurance")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_insurance")}
                </p>
              </div>
              <div className="item-btn">
                <p>Read more</p>
                <NavLink to={`/${language.toLowerCase()}/insurance`}>
                  <button className="home_slider-btn">
                    <img src={btn} alt="More" draggable="false" />
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div key={1} className="grid services">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img2}
                  alt="services"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_services")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_services")}
                </p>
              </div>
              <div className="item-btn">
                <p>Read more</p>
                <NavLink to={`/${language.toLowerCase()}/bookpay`}>
                  <button className="home_slider-btn">
                    <img src={btn} alt="More" draggable="false" />
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div key={2} className="grid value">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img3}
                  alt="value"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_value")}</h3>
                <p id="item-hometext">{translate("home_slider_text_value")}</p>
              </div>
            </div>
          </div>
          <div key={3} className="grid auction">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img4}
                  alt="auction"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_auction")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_auction")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid assistance">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img5}
                  alt="assistance"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_assistance")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_assistance")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid parking">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img6}
                  alt="parking"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_parking")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_parking")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid fuel">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img7}
                  alt="fuel"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_fuel")}</h3>
                <p id="item-hometext">{translate("home_slider_text_fuel")}</p>
              </div>
            </div>
          </div>
          <div className="grid accident">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img8}
                  alt="accident"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_accident")}</h3>
                <p id="item-hometext">
                  {translate("home_slider_text_accident")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid fines">
            <div className="item">
              <div className="img-wrap">
                <img
                  src={images[language as Lang].img9}
                  alt="fines"
                  draggable="false"
                />
              </div>
            </div>
            <div className="item">
              <div className="item-content">
                <h3>{translate("home_slider_title_fines")}</h3>
                <p id="item-hometext">{translate("home_slider_text_fines")}</p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default HomeServices;
